/**
 * Key codes are deprecated. Should use Keys below.
 */
export const keyCodes = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  pgup: 33,
  pgdown: 34,
  enter: 13,
  escape: 27,
  tab: 9
};

export enum Key {
  // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
  Enter = "Enter",
  Backspace = "Backspace",
  Delete = "Delete",
  Tab = "Tab",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown"
}

export const supportedKeyCodes = Object.values(keyCodes);
